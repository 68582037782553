import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexInternationalization',
  setup(__props) {

const LanguageInternationa = defineAsyncComponent(() => import("@/components/theme/internationalization/LanguageInternationa.vue"))
const LanguagePicker = defineAsyncComponent(() => import("@/components/theme/internationalization/LanguagePicker.vue"))
const LanguageView = defineAsyncComponent(() => import("@/components/theme/internationalization/LanguageView.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(LanguageInternationa)),
      _createVNode(_unref(LanguagePicker)),
      _createVNode(_unref(LanguageView))
    ])
  ]))
}
}

})