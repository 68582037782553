<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card login-dark">
          <div>
            <div>
              <router-link class="logo" to="/">
                <img class="img-fluid for-light"
                     src="@/assets/images/logo/logo_full.svg" alt="looginpage">
                <img class="img-fluid for-dark"
                     src="@/assets/images/logo/logo_dark.png" alt="looginpage">
              </router-link>
            </div>
            <div class="login-main">
              <form class="theme-form" @submit.prevent="createAccount">
                <h2>Create your account</h2>
                <p>Enter your personal details to create account</p>
                <div class="form-group">
                  <label class="col-form-label pt-0">Your Name</label>
                  <div class="row g-2">
                    <div class="col-12">
                      <input v-model="formValues.name" class="form-control" type="text" required placeholder="Name">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input v-model="formValues.email" class="form-control" type="email" required
                         placeholder="your-email@gmail.com">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Phone number</label>
                  <input v-model="formValues.phone" class="form-control" type="text" required
                         placeholder="+99512345678">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input v-model="formValues.password" class="form-control" :type="type"
                           placeholder="*********">
                    <div class="show-hide"><span :class="type == 'password' ? 'show' : ''"
                                                 @click="showPassword"></span></div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Repeat password</label>
                  <div class="form-input position-relative">
                    <input v-model="password" class="form-control" :type="type"
                           placeholder="*********">
                    <div class="show-hide"><span :class="type == 'password' ? 'show' : ''"
                                                 @click="showPassword"></span></div>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Agree with<a class="ms-2" href="#">Privacy
                      Policy</a></label>
                  </div>
                  <button class="btn btn-primary btn-block w-100" type="submit">Create Account</button>
                </div>
                <p class="mt-4 mb-0">Already have an account?
                  <router-link class="ms-2" to="/auth/login">Sign
                    in
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts" setup>
import {ref} from 'vue'
import {AuthService, emptyRegisterRequest, RegistrationRequest} from "@/api/auth";
import {APIError} from "@/api/errors";
import {toast} from "vue3-toastify";
import {useRouter} from "vue-router";

const type = ref<string>('password')

const router = useRouter()
const password = ref<string>('')
const formValues = ref<RegistrationRequest>({...emptyRegisterRequest})

function showPassword() {
  if (type.value === 'password') {
    type.value = 'text';
  } else {
    type.value = 'password';
  }
}

const createAccount = async () => {
  if (formValues.value.password !== password.value) {
    toast.error("passwords is not equals")
    return
  }
  const resp = await AuthService.register(formValues.value)
  if (resp instanceof APIError){
    toast.error(resp.message)
    return
  }
  await router.push('/')
}
</script>

<style>
.img-fluid {
  height: auto !important;
}
</style>