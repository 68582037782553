import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid search-page" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexSearch',
  setup(__props) {

const SearchBar = defineAsyncComponent(() => import("@/components/theme/search/SearchBar.vue"))
const SearchTab = defineAsyncComponent(() => import("@/components/theme/search/SearchTab.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(SearchBar)),
          _createVNode(_unref(SearchTab))
        ])
      ])
    ])
  ]))
}
}

})