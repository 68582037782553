<script setup lang="ts">
import {defineAsyncComponent, ref} from "vue";
import {toast} from "vue3-toastify";
import {FeedbackService} from "@/api/feedbacks";
import {APIError} from "@/api/errors";

const Card3 = defineAsyncComponent(() => import("@/components/common/card/CardData3.vue"))

const formMessage = ref<string>("")
const addFeedback = async () => {
  if (formMessage.value === "") {
    toast.error("message is required")
    return
  }
  const result = await FeedbackService.addFeedback({message: formMessage.value})
  if (result instanceof APIError) {
    toast.error(result.message)
    return
  }
  toast.success("Message was successfully sent.")
  formMessage.value = ""
}
</script>

<template>
  <Card3 colClass="col-md-6" pre="true" cardBodyClass="custom-input" preClass="f-m-light mt-1" headerTitle="true"
         title="Для подключения видеокамер или тарифа свяжитесь с нами" desc="">
    <div class="card-wrapper border rounded-3">
      <form class="row g-3" @submit.prevent="addFeedback">
        <div class="col-md-12">
          <label class="form-label" for="inputEmail4">Message</label>
          <textarea v-model="formMessage" class="form-control" type="text" placeholder="Enter Your Message" name="message"
                    required></textarea>
        </div>
        <div class="col-12">
          <button class="btn btn-primary" type="submit">Send</button>
        </div>
      </form>
    </div>
  </Card3>
</template>

<style scoped lang="scss">
:deep(.card-header) h2 {
  text-transform: none !important;
}
</style>