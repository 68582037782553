import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-wrapper border rounded-3" }
const _hoisted_2 = { class: "col-md-12" }

import {defineAsyncComponent, ref} from "vue";
import {toast} from "vue3-toastify";
import {FeedbackService} from "@/api/feedbacks";
import {APIError} from "@/api/errors";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactFrom',
  setup(__props) {

const Card3 = defineAsyncComponent(() => import("@/components/common/card/CardData3.vue"))

const formMessage = ref<string>("")
const addFeedback = async () => {
  if (formMessage.value === "") {
    toast.error("message is required")
    return
  }
  const result = await FeedbackService.addFeedback({message: formMessage.value})
  if (result instanceof APIError) {
    toast.error(result.message)
    return
  }
  toast.success("Message was successfully sent.")
  formMessage.value = ""
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card3), {
    colClass: "col-md-6",
    pre: "true",
    cardBodyClass: "custom-input",
    preClass: "f-m-light mt-1",
    headerTitle: "true",
    title: "Для подключения видеокамер или тарифа свяжитесь с нами",
    desc: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          class: "row g-3",
          onSubmit: _withModifiers(addFeedback, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("label", {
              class: "form-label",
              for: "inputEmail4"
            }, "Message", -1)),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formMessage).value = $event)),
              class: "form-control",
              type: "text",
              placeholder: "Enter Your Message",
              name: "message",
              required: ""
            }, null, 512), [
              [_vModelText, formMessage.value]
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              type: "submit"
            }, "Send")
          ], -1))
        ], 32)
      ])
    ]),
    _: 1
  }))
}
}

})