import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { ref, defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexSample',
  setup(__props) {

const Card3 = defineAsyncComponent(() => import("@/components/common/card/CardData3.vue"))
let desc = ref<string>("Here you can enter a sub-title for your card.")

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Card3), {
        colClass: "col-sm-12",
        pre: "true",
        preClass: "f-m-light mt-1",
        cardClass: "title-line",
        headerTitle: "true",
        title: "Sample Card",
        desc: _unref(desc),
        text: "true"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Web Design Trends: "),
            _createTextVNode(" Stay up-to-date with the latest trends in web design, such as the use of animations, micro-interactions, dark mode, and unique navigation techniques.Keep your website's navigation simple and intuitive, allowing users to find what they need easily without overwhelming them with options.")
          ], -1),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Design Tools: "),
            _createTextVNode(" Information on popular design software like Adobe Photoshop, Sketch, Figma, or Adobe XD, along with tips and tricks for efficient workflow and collaboration.Compress and optimize images to improve website loading speed and overall performance, providing a better user experience.")
          ], -1),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Front-End Development: "),
            _createTextVNode(" A basic understanding of HTML, CSS, and JavaScript can enhance your web design skills, enabling you to create interactive and dynamic elements.Ensure sufficient contrast between text and background colors to enhance readability and accessibility, especially for users with visual impairments.")
          ], -1)
        ])),
        _: 1
      }, 8, ["desc"])
    ])
  ]))
}
}

})