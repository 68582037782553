import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "user-profile" }
const _hoisted_3 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexProfile',
  setup(__props) {

const ProfileHeader = defineAsyncComponent(() => import("@/components/theme/user/profile/ProfileHeader.vue"))
// const ProfilePost = defineAsyncComponent(() => import("@/components/theme/user/profile/ProfilePost.vue"))
// const ProfilePost1 = defineAsyncComponent(() => import("@/components/theme/user/profile/ProfilePost1.vue"))
// const ProfilePost2 = defineAsyncComponent(() => import("@/components/theme/user/profile/ProfilePost2.vue"))
// const ProfilePost3 = defineAsyncComponent(() => import("@/components/theme/user/profile/ProfilePost3.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(ProfileHeader))
      ])
    ])
  ]))
}
}

})