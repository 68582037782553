import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import BodyView from "@/layout/BodyView.vue"
import AuthView from "@/components/common/block/auth/AuthView.vue"
import LoginPage from "@/components/common/block/auth/LoginPage.vue"
import indexDefault from "@/pages/dashbords/indexDefault.vue"
import indexEcommerce from "@/pages/dashbords/indexEcommerce.vue"
import indexProject from "@/pages/dashbords/indexProject.vue"
import indexGeneral from "@/pages/widgets/indexGeneral.vue"
import indexChart from "@/pages/widgets/indexChart.vue"
import indexProjectlist from "@/pages/project/indexProjectlist.vue"
import indexCreateProject from "@/pages/project/indexCreateProject.vue"
import indexFileManeger from "@/pages/filemaneger/indexFileManeger.vue"
import indexKanbanBoard from "@/pages/kanban/indexKanbanBoard.vue"
import indexProduct from "@/pages/ecommerce/indexProduct.vue"
import indexAddProduct from "@/pages/ecommerce/indexAddProduct.vue"
import indexProductPage from "@/pages/ecommerce/indexProductPage.vue"
import indexProductList from "@/pages/ecommerce/indexProductList.vue"
import indexPaymentDetail from "@/pages/ecommerce/indexPaymentDetail.vue"
import indexOrder from "@/pages/ecommerce/indexOrder.vue"
import indexInvoiceOne from "@/pages/ecommerce/indexInvoiceOne.vue"
import indexInvoiceTwo from "@/pages/ecommerce/indexInvoiceTwo.vue"
import indexInvoiceThree from "@/pages/ecommerce/indexInvoiceThree.vue"
import indexInvoiceFour from "@/pages/ecommerce/indexInvoiceFour.vue"
import indexInvoiceFive from "@/pages/ecommerce/indexInvoiceFive.vue"
import indexInvoiceSix from "@/pages/ecommerce/indexInvoiceSix.vue"
import indexCart from "@/pages/ecommerce/indexCart.vue"
import indexWishlist from "@/pages/ecommerce/indexWishlist.vue"
import indexCheckout from "@/pages/ecommerce/indexCheckout.vue"
import indexPricing from "@/pages/ecommerce/indexPricing.vue"
import indexCategory from "@/pages/ecommerce/indexCategory.vue"
import indexLetterBox from "@/pages/letterbox/indexLetterBox.vue"
import indexPrivateChat from "@/pages/chat/indexPrivateChat.vue"
import indexGroupChat from "@/pages/chat/indexGroupChat.vue"
import indexProfile from "@/pages/user/indexProfile.vue"
import indexEdit from "@/pages/user/indexEdit.vue"
import indexCard from "@/pages/user/indexCard.vue"
import indexBookmark from "@/pages/bookmark/indexBookmark.vue"
import indexContact from "@/pages/contacts/indexContact.vue"
import indexTask from "@/pages/task/indexTask.vue"
import indexCalendar from "@/pages/calendar/indexCalendar.vue"
import indexSoical from "@/pages/socialapp/indexSoical.vue"
import indexTodo from "@/pages/todo/indexTodo.vue"
import indexSearch from "@/pages/search/indexSearch.vue"
import indexValidation from "@/pages/forms/formcontrols/indexValidation.vue"
import indexInputs from "@/pages/forms/formcontrols/indexInputs.vue"
import indexCheckbox from "@/pages/forms/formcontrols/indexCheckbox.vue"
import indexGroups from "@/pages/forms/formcontrols/indexGroups.vue"
import indexMask from "@/pages/forms/formcontrols/indexMask.vue"
import indexMega from "@/pages/forms/formcontrols/indexMega.vue"
import indexDatapicker from "@/pages/forms/formwidgets/indexDatapicker.vue"
import indexTouchspin from "@/pages/forms/formwidgets/indexTouchspin.vue"
import indexSelect from "@/pages/forms/formwidgets/indexSelect.vue"
import indexSwitch from "@/pages/forms/formwidgets/indexSwitch.vue"
import indexTypeahead from "@/pages/forms/formwidgets/indexTypeahead.vue"
import indexClipboard from "@/pages/forms/formwidgets/indexClipboard.vue"
import formWizard from "@/pages/forms/formlayout/formWizard.vue"
import formWizard2 from "@/pages/forms/formlayout/formWizard2.vue"
import indexTwofactor from "@/pages/forms/formlayout/indexTwofactor.vue"
import indexBootstrap from "@/pages/table/indexBootstrap.vue"
import indexComponent from "@/pages/table/indexComponent.vue"
import indexInit from "@/pages/table/indexInit.vue"
import indexAnimate from "@/pages/animation/indexAnimate.vue"
import indexAos from "@/pages/animation/indexAos.vue"
import indexFlag from "@/pages/icons/indexFlag.vue"
import indexFontawesome from "@/pages/icons/indexFontawesome.vue"
import indexIcoicon from "@/pages/icons/indexIcoicon.vue"
import indexThemify from "@/pages/icons/indexThemify.vue"
import indexFeather from "@/pages/icons/indexFeather.vue"
import indexWhether from "@/pages/icons/indexWhether.vue"
import indexApexchart from "@/pages/charts/indexApexchart.vue"
import indexGoogle from "@/pages/charts/indexGoogle.vue"
import indexChartist from "@/pages/charts/indexChartist.vue"
import indexSample from "@/pages/samplepage/indexSample.vue"
import indexButtons from "@/pages/buttons/indexButtons.vue"
import indexInternationalization from "@/pages/internationalization/indexInternationalization.vue"
import indexErrorPage1 from "@/pages/error/indexErrorPage1.vue"
import indexErrorPage2 from "@/pages/error/indexErrorPage2.vue"
import indexErrorPage3 from "@/pages/error/indexErrorPage3.vue"
import indexErrorPage4 from "@/pages/error/indexErrorPage4.vue"
import indexErrorPage5 from "@/pages/error/indexErrorPage5.vue"
import indexErrorPage6 from "@/pages/error/indexErrorPage6.vue"
import indexComingsoonPage from "@/pages/comingsoon/indexComingsoonPage.vue"
import indexComingsoonImage from "@/pages/comingsoon/indexComingsoonImage.vue"
import indexComingsoonVideo from "@/pages/comingsoon/indexComingsoonVideo.vue"
import loginSimple from "@/pages/authentication/loginSimple.vue"
import visualImage from "@/pages/authentication/visualImage.vue"
import loginImageTwo from "@/pages/authentication/loginImageTwo.vue"
import loginValidation from "@/pages/authentication/loginValidation.vue"
import loginTooltip from "@/pages/authentication/loginTooltip.vue"
import loginSweetalert from "@/pages/authentication/loginSweetalert.vue"
import registerSimple from "@/pages/authentication/registerSimple.vue"
import registerImage from "@/pages/authentication/registerImage.vue"
import registerImageTwo from "@/pages/authentication/registerImageTwo.vue"
import unlockUser from "@/pages/authentication/unlockUser.vue"
import forgetPassword from "@/pages/authentication/forgetPassword.vue"
import resetPassword from "@/pages/authentication/resetPassword.vue"
import maintenanceView from "@/pages/authentication/maintenanceView.vue"
import indexGallery from "@/pages/gallery/indexGallery.vue"
import indexGriddesc from "@/pages/gallery/indexGriddesc.vue"
import indexMasonry from "@/pages/gallery/indexMasonry.vue"
import indexMasonarydesc from "@/pages/gallery/indexMasonarydesc.vue"
import indexHoverGallery from "@/pages/gallery/indexHoverGallery.vue"
import indexDetails from "@/pages/blog/indexDetails.vue"
import indexSingle from "@/pages/blog/indexSingle.vue"
import indexAdd from "@/pages/blog/indexAdd.vue"
import indexFaq from "@/pages/faq/indexFaq.vue"
import indexGoogleMap from "@/pages/maps/indexGoogle.vue"
import indexLeaflet from "@/pages/maps/indexLeaflet.vue"
import indexCk from "@/pages/editor/indexCk.vue"
import simpleEditor from "@/pages/editor/simpleEditor.vue"
import indexKnowledgebase from "@/pages/knowledgebase/indexKnowledgebase.vue"
import indexSupport from "@/pages/support/indexSupport.vue"
import indexJobCard from "@/pages/job/indexCard.vue"
import indexJobDetails from "@/pages/job/indexDetails.vue"
import indexList from "@/pages/job/indexList.vue"
import indexApply from "@/pages/job/indexApply.vue"
import indexLearning from "@/pages/learning/indexLearning.vue"
import indexCourse from "@/pages/learning/indexCourse.vue"
import indexScrollable from "@/pages/advance/indexScrollable.vue"
import indexTree from "@/pages/advance/indexTree.vue"
import indexToasts from "@/pages/advance/indexToasts.vue"
import indexRating from "@/pages/advance/indexRating.vue"
import indexDropzone from "@/pages/advance/indexDropzone.vue"
import indexTour from "@/pages/advance/indexTour.vue"
import indexSweetalert from "@/pages/advance/indexSweetalert.vue"
import animationModal from "@/pages/advance/animationModal.vue"
import owlCarousel from "@/pages/advance/owlCarousel.vue"
import indexRibbon from "@/pages/advance/indexRibbon.vue"
import indexPagenation from "@/pages/advance/indexPagenation.vue"
import indexBreadcrumb from "@/pages/advance/indexBreadcrumb.vue"
import indexRange from "@/pages/advance/indexRange.vue"
import indexCropper from "@/pages/advance/indexCropper.vue"
import indexBasiccard from "@/pages/advance/indexBasiccard.vue"
import indexCreative from "@/pages/advance/indexCreative.vue"
import indexDraggable from "@/pages/advance/indexDraggable.vue"
import indexTimeline from "@/pages/advance/indexTimeline.vue"
import indexTypography from "@/pages/uikits/indexTypography.vue"
import indexAvatars from "@/pages/uikits/indexAvatars.vue"
import indexHelper from "@/pages/uikits/indexHelper.vue"
import indexGrid from "@/pages/uikits/indexGrid.vue"
import indexTagPills from "@/pages/uikits/indexTagPills.vue"
import indexProgress from "@/pages/uikits/indexProgress.vue"
import indexModal from "@/pages/uikits/indexModal.vue"
import indexAlert from "@/pages/uikits/indexAlert.vue"
import indexPopover from "@/pages/uikits/indexPopover.vue"
import indexTooltip from "@/pages/uikits/indexTooltip.vue"
import indexDropdown from "@/pages/uikits/indexDropdown.vue"
import indexAccordion from "@/pages/uikits/indexAccordion.vue"
import indexTabs from "@/pages/uikits/indexTabs.vue"
import indexLists from "@/pages/uikits/indexLists.vue"
import {APIError} from "@/api/errors";
import {useUserStore} from "@/store/users";
import {User, UserService} from "@/api/user";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: BodyView,
        meta: {
            title: 'OwlCam Cloud',
        },
        children: [
            {
                path: '',
                name: 'defaultRoot',
                component: indexDefault,
                meta: {
                    title: 'OwlCam Cloud',
                }
            }
        ]
    },
    {
        path: "/auth",
        component: AuthView,
        children: [
            {
                path: "login",
                name: "login",
                component: LoginPage,
                meta: {
                    title: 'OwlCam Cloud',
                }
            },
            {
                path: "register",
                name: "register",
                component: registerSimple,
                meta: {
                    title: 'Register | OwlCam Cloud',
                }
            },
            {
                path: "unlock_user",
                name: "unlockUser",
                component: unlockUser,
                meta: {
                    title: 'Unlock User | OwlCam Cloud',
                }
            },
            {
                path: "forget_password",
                name: "forgetPassword",
                component: forgetPassword,
                meta: {
                    title: 'Forget Password | OwlCam Cloud',
                }
            },
            {
                path: "reset_password",
                name: "resetPassword",
                component: resetPassword,
                meta: {
                    title: 'Reset Password | OwlCam Cloud',
                }
            },
        ]
    },
    {
        path: "/dashboard",
        component: BodyView,
        children: [
            {
                path: "default",
                name: "default",
                component: indexDefault,
                meta: {
                    title: 'Dashboards | OwlCam Cloud',
                }
            },
            {
                path: "project",
                name: "project",
                component: indexProject,
                meta: {
                    title: 'Dashboards Project | OwlCam Cloud',
                }
            },
            {
                path: "ecommerce",
                name: "ecommerce",
                component: indexEcommerce,
                meta: {
                    title: 'Dashboards Ecommerce | OwlCam Cloud',
                }
            },

        ]
    },
    {
        path: "/project",
        component: BodyView,
        children: [
            {
                path: "project_list",
                name: "projectList",
                component: indexProjectlist,
                meta: {
                    title: 'Project List| OwlCam Cloud',
                }
            },
            {
                path: "project_create",
                name: "createProject",
                component: indexCreateProject,
                meta: {
                    title: 'Create Project| OwlCam Cloud',
                }
            }
        ]
    },
    {
        path: "/app",
        component: BodyView,
        children: [
            {
                path: "file_manager",
                name: "fileManager",
                component: indexFileManeger,
                meta: {
                    title: 'File Manager| OwlCam Cloud',
                }
            },
            {
                path: "kanban_board",
                name: "kanbanBoard",
                component: indexKanbanBoard,
                meta: {
                    title: 'kanban Board| OwlCam Cloud',
                }
            },
            {
                path: "letter_box",
                name: "letterbox",
                component: indexLetterBox,
                meta: {
                    title: 'Letter Box| OwlCam Cloud',
                }
            },
            {
                path: "private_chat",
                name: "Chatapp",
                component: indexPrivateChat,
                meta: {
                    title: 'Private Chat| OwlCam Cloud',
                }
            },
            {
                path: "group_chat",
                name: "Group Chat",
                component: indexGroupChat,
                meta: {
                    title: 'Group Chat| OwlCam Cloud',
                }
            },
            {
                path: "bookmark",
                name: "bookmark",
                component: indexBookmark,
                meta: {
                    title: 'Bookmark| OwlCam Cloud',
                }
            },
            {
                path: "contact",
                name: "contacts",
                component: indexContact,
                meta: {
                    title: 'contact| OwlCam Cloud',
                }
            },
            {
                path: "todo",
                name: "todo",
                component: indexTodo,
                meta: {
                    title: 'To Do| OwlCam Cloud',
                }
            },
            {
                path: "task",
                name: "task",
                component: indexTask,
                meta: {
                    title: 'Task| OwlCam Cloud',
                }
            },
            {
                path: "calendar",
                name: "calendar",
                component: indexCalendar,
                meta: {
                    title: 'Calendar| OwlCam Cloud',
                }
            },
            {
                path: "buttons",
                name: "button",
                component: indexButtons,
                meta: {
                    title: 'Buttons | OwlCam Cloud',
                }
            },

        ]
    },
    {
        path: "/ecommerce",
        component: BodyView,
        children: [
            // {
            //     path: "add_product",
            //     name: "addporduct",
            //     component: indexAddProduct,
            //     meta: {
            //         title: 'Add Product | OwlCam Cloud',
            //     }
            // },
            {
                path: "product",
                name: "product",
                component: indexProduct,
                meta: {
                    title: 'Product | OwlCam Cloud',
                }
            },
            {
                path: "cart",
                name: "cart",
                component: indexCart,
                meta: {
                    title: 'Cart | OwlCam Cloud',
                }
            },
            // {
            //     path: "details/:id",
            //     name: "productPage",
            //     component: indexProductPage,
            //     meta: {
            //         title: 'Product Page | OwlCam Cloud',
            //     }
            // },
            //
            // {
            //     path: "payment_details",
            //     name: "paymentDetail",
            //     component: indexPaymentDetail,
            //     meta: {
            //         title: 'Payment Detail | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "invoice_1",
            //     name: "Invoice_1",
            //     component: indexInvoiceOne,
            //     meta: {
            //         title: 'Invoice | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "invoice_2",
            //     name: "Invoice_2",
            //     component: indexInvoiceTwo,
            //     meta: {
            //         title: 'Invoice Two | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "invoice_3",
            //     name: "Invoice_3",
            //     component: indexInvoiceThree,
            //     meta: {
            //         title: 'Invoice Three | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "invoice_4",
            //     name: "Invoice_4",
            //     component: indexInvoiceFour,
            //     meta: {
            //         title: 'Invoice Four | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "invoice_5",
            //     name: "Invoice_5",
            //     component: indexInvoiceFive,
            //     meta: {
            //         title: 'Invoice Five | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "invoice_6",
            //     name: "Invoice_6",
            //     component: indexInvoiceSix,
            //     meta: {
            //         title: 'Invoice Six | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "checkout",
            //     name: "Checkout",
            //     component: indexCheckout,
            //     meta: {
            //         title: 'Checkout | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "pricing",
            //     name: "pricing",
            //     component: indexPricing,
            //     meta: {
            //         title: 'Pricing | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "wishlist",
            //     name: "Wishlist",
            //     component: indexWishlist,
            //     meta: {
            //         title: 'Wish List | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "order_history",
            //     name: "Orderhistory",
            //     component: indexOrder,
            //     meta: {
            //         title: 'Order History | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "product_list",
            //     name: "productlist",
            //     component: indexProductList,
            //     meta: {
            //         title: 'Product list | OwlCam Cloud',
            //     }
            // },
            // {
            //     path: "category",
            //     name: "category page",
            //     component: indexCategory,
            //     meta: {
            //         title: 'Category Page | OwlCam Cloud',
            //     }
            // }
        ]
    },
    {
        path: "/users",
        component: BodyView,
        children: [
            {
                path: "profile",
                name: "userProfile",
                component: indexProfile,
                meta: {
                    title: 'User Profile| OwlCam Cloud',
                }
            },
            {
                path: "edit",
                name: "indexEdit",
                component: indexEdit,
                meta: {
                    title: 'User Edit| OwlCam Cloud',
                }
            },
            {
                path: "cards",
                name: "usercard",
                component: indexCard,
                meta: {
                    title: 'User Cards| OwlCam Cloud',
                }
            }
        ]
    },
    {
        path: "/pages",
        component: BodyView,
        children: [
            {
                path: "social_app",
                name: "socialapp",
                component: indexSoical,
                meta: {
                    title: 'Social App| OwlCam Cloud',
                }
            },
            {
                path: "search",
                name: "searchresult",
                component: indexSearch,
                meta: {
                    title: 'Search| OwlCam Cloud',
                }
            },
            {
                path: "sample_page",
                name: "samplepage",
                component: indexSample,
                meta: {
                    title: 'Simple Page| OwlCam Cloud',
                }
            },
            {
                path: "internationalization",
                name: "Internationalization",
                component: indexInternationalization,
                meta: {
                    title: 'Internationalization| OwlCam Cloud',
                }
            },
            {
                path: "faq",
                name: "faq",
                component: indexFaq,
                meta: {
                    title: 'Faq| OwlCam Cloud',
                }
            },
            {
                path: "support",
                name: "Support",
                component: indexSupport,
                meta: {
                    title: 'Support | OwlCam Cloud',
                }
            }
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (typeof (to.meta.title) === 'string') {
        document.title = to.meta.title;
    }
    let user: User | null = null
    const userResp = await UserService.getMe()
    if (!(userResp instanceof APIError)) {
        user = userResp as User;
        const store = useUserStore()
        store.setUser(user)
    }
    const path = ['/auth/login', '/auth/register'];
    if (!path.includes(to.path) && user === null) {
        return next('/auth/login');
    }
    if (path.includes(to.path) && user !== null) {
        return next('/');
    }
    return next();

});

export default router
