import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row",
  id: "wrap"
}

import { ref, defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexCalendar',
  setup(__props) {

const Card3 = defineAsyncComponent(() => import("@/components/common/card/CardData3.vue"))
const CalenderView = defineAsyncComponent(() => import("@/components/theme/calender/CalenderView.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card3), { colClass: "container-fluid calendar-basic" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(CalenderView))
      ])
    ]),
    _: 1
  }))
}
}

})