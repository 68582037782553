import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexProject',
  setup(__props) {

const UpgradeCard = defineAsyncComponent(() => import("@/components/theme/dashboards/project/UpgradeCard.vue"))
const ActiveProjects = defineAsyncComponent(() => import("@/components/common/ActiveProjects.vue"))
const ProjectsEarnings = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ProjectsEarnings.vue"))
const TotalClients = defineAsyncComponent(() => import("@/components/theme/dashboards/project/TotalClients.vue"))
const ProjectsOverview = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ProjectsOverview.vue"))
const CalendarView = defineAsyncComponent(() => import("@/components/theme/dashboards/project/CalendarView.vue"))
const ProjectsStatus = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ProjectsStatus.vue"))
const ChatApplication = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ChatApplication.vue"))
const RecentProject = defineAsyncComponent(() => import("@/components/theme/dashboards/project/RecentProject.vue"))
const AvgEarnings = defineAsyncComponent(() => import("@/components/theme/dashboards/project/AvgEarnings.vue"))
const DailyTask = defineAsyncComponent(() => import("@/components/theme/dashboards/project/DailyTask.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(UpgradeCard)),
      _createVNode(_unref(ActiveProjects), { col: "col-xl-3 col-sm-6" }),
      _createVNode(_unref(ProjectsEarnings)),
      _createVNode(_unref(TotalClients)),
      _createVNode(_unref(ProjectsOverview)),
      _createVNode(_unref(CalendarView)),
      _createVNode(_unref(ProjectsStatus)),
      _createVNode(_unref(ChatApplication)),
      _createVNode(_unref(RecentProject)),
      _createVNode(_unref(AvgEarnings)),
      _createVNode(_unref(DailyTask))
    ])
  ]))
}
}

})