import {HeaderKey} from "@/api/headers";

export const oopsMessage = "Ops, something went wrong.";

export class APIError {
    private readonly _message: string;
    private readonly _status: number;
    private readonly _code?: number;
    private readonly _detail?: string;

    constructor(message: string, status: number, code?: number, detail?: string) {
        this._message = message;
        this._status = status;
        this._code = code;
        this._detail = detail;
    }

    get message(): string {
        return this._message;
    }

    get status(): number {
        return this._status || 0;
    }

    get code(): number {
        return this._code || 0;
    }

    get detail(): string {
        return this._detail || "";
    }
}

export const apiErrorFromResponse = async (
    resp: Response,
): Promise<APIError> => {
    if (resp.status < 500) {
        const respJson = await resp.json();
        let message = respJson.detail || respJson.message || oopsMessage;
        const detail = resp.headers.get(HeaderKey.ErrDetail)
        if (detail) {
            message += `: ${detail}`;
        }
        return new APIError(message, resp.status, respJson.code, respJson.detail);
    }
    return new APIError(oopsMessage, resp.status);
};
