import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo/logo_full.svg'
import _imports_1 from '@/assets/images/logo/logo_dark.png'


const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card login-dark" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-input position-relative" }
const _hoisted_9 = ["type"]
const _hoisted_10 = { class: "show-hide" }
const _hoisted_11 = { class: "form-group mb-0" }
const _hoisted_12 = { class: "mt-4 mb-0 text-center" }

import {ref} from "vue"
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {useRouter} from 'vue-router'
import {AuthService} from "@/api/auth";
import {APIError} from "@/api/errors";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const type = ref<string>('password')
const email = ref<string>("")
const password = ref<string>("")
const router = useRouter()
const isShowPassword = ref(false)

function showPassword() {
  if (isShowPassword.value) {
    type.value = 'password'
  } else {
    type.value = 'text'
  }
  isShowPassword.value = !isShowPassword.value;
}

async function doLogin() {
  const res = await AuthService.auth({username: email.value, password: password.value})
  if (res instanceof APIError) {
    toast.error(res.message, {position: 'top-right', autoClose: 5000});
    return
  }
  router.replace('/');
  localStorage.setItem('user', email.value)
  localStorage.setItem('token', res.token)
  localStorage.setItem("SidebarType", 'compact-wrapper')
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                class: "logo",
                to: "/"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    class: "img-fluid for-light",
                    src: _imports_0,
                    alt: "looginpage"
                  }, null, -1),
                  _createElementVNode("img", {
                    class: "img-fluid for-dark",
                    src: _imports_1,
                    alt: "looginpage"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "theme-form",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Sign in to account", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("p", { class: "f-m-light mt-1" }, "Enter your email & password to login", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                    class: "form-control",
                    type: "email",
                    required: "",
                    placeholder: "your-email@gmail.com"
                  }, null, 512), [
                    [_vModelText, email.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                      type: type.value,
                      required: "",
                      placeholder: "*********"
                    }, null, 8, _hoisted_9), [
                      [_vModelDynamic, password.value]
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", {
                        class: _normalizeClass({show: !isShowPassword.value, hide: isShowPassword.value}),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "checkbox p-0" }, [
                    _createElementVNode("input", {
                      id: "checkbox1",
                      type: "checkbox"
                    }),
                    _createElementVNode("label", {
                      class: "text-muted",
                      for: "checkbox1"
                    }, "Remember password")
                  ], -1)),
                  _createVNode(_component_router_link, { to: "/authentication/forget_password" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Forgot password? ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", { class: "text-end mt-3" }, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-block w-100",
                      type: "submit",
                      onClick: doLogin
                    }, "Sign in ")
                  ])
                ]),
                _createElementVNode("p", _hoisted_12, [
                  _cache[9] || (_cache[9] = _createTextVNode("Don't have account? ")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/register"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Create Account ")
                    ])),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})