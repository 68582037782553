export const layout = {
    settings: {
        layout_type: "ltr",
        layout: "default",
        sidebar_icon: "stroke-svg",
        sidebar_setting: "compact-wrapper"
    },
    color: {
        layout_version: "light",
        primary_color: "#009DB5",
        secondary_color: "#F94C8E"
    }
}