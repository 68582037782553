import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { ref, defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexSupport',
  setup(__props) {

const Card3 = defineAsyncComponent(() => import("@/components/common/card/CardData3.vue"))
const SupportTicket = defineAsyncComponent(() => import("@/components/theme/support/SupportTicket.vue"))
const SupportTable = defineAsyncComponent(() => import("@/components/theme/support/SupportTable.vue"))
let desc = ref<string>("List of ticket opend by customers")

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Card3), {
        colClass: "col-sm-12 ",
        title: "Support Ticket List",
        pre: "true",
        preClass: "f-m-light mt-1",
        headerTitle: "true",
        text: "true",
        desc: _unref(desc)
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(SupportTicket)),
          _createVNode(_unref(SupportTable))
        ]),
        _: 1
      }, 8, ["desc"])
    ])
  ]))
}
}

})