import { defineStore } from "pinia";
import { ref, onMounted, computed } from "vue";
import { menu } from "@/core/data/menu";
import { useRoute } from "vue-router";

interface searchdatas {
    icon: string,
    path: string,
    title: string
}
interface search {
    icon: string,
    path: string,
    title: string,
    bookmark: string
}
export const useMenuStore = defineStore("menu", () => {

    let data = ref(menu)
    let togglesidebar = ref<boolean>(true);
    let activeoverlay = ref<boolean>(true);
    let customizer = ref<string>("");
    let searchData = ref<searchdatas[]>([]);
    let searchDatas = ref<search[]>([]);
    let searchOpen = ref<boolean>(false);
    let hideRightArrowRTL = ref<boolean>(false)
    let hideLeftArrowRTL = ref<boolean>(true)
    let hideRightArrow = ref<boolean>(true)
    let hideLeftArrow = ref<boolean>(true)
    let width = ref<number>(0)
    let height = ref<number>(0)
    let margin = ref<number>(0)
    let menuWidth = ref<number>(0)
    let perentName = ref<string>('')
    let subName = ref<string>('')
    let childName = ref<string>('')
    let bodyToggle = ref(false)
    let perentToggle = ref<boolean>(false)
    let subToggle = ref<boolean>(false)
    let childToggle = ref<boolean>(false)
    let searchQuery = ref<string>('');

    let active = ref<boolean>(false)

    const filteredMenuItems = computed(() => {
        let filterDataArray: any = []
        data.value.forEach((menuItem: any, i: number) => {


            let filterData = menuItem.children?.filter((menu: any) => {
                if (menu.title) {
                    return menu.title.toLowerCase().includes(searchQuery.value.toLowerCase())
                }
            })
            filterDataArray.push({ ...menuItem, children: filterData })
        })

        return filterDataArray
    });
    function openActives() {
        active.value = !active.value
    }
    function togglePinned(item: any) {

        item.isPinned = !item.isPinned;
    };
    function toggle_sidebar() {
        togglesidebar.value = !togglesidebar.value;
        if (window.innerWidth < 991) {
            activeoverlay.value = true;
        } else {
            activeoverlay.value = false;
        }
        activeoverlay.value = false;
    }
    function subMenuToggle(Name: string) {
        perentName.value = perentName.value != Name ? Name : ""
        perentToggle.value = perentName.value != "" ? true : false
    }
    function subChildMenu(subTitle: string) {
        subName.value = subName.value != subTitle ? subTitle : ''
        subToggle.value = subName.value != "" ? true : false
    }
    function childMenu(childTitle: string) {
        childName.value = childName.value != childTitle ? childTitle : "";
        childToggle.value = childName.value != '' ? true : false

    }
    function searchTerm(term: any) {

        const items: any = [];

        const searchval = term.toLowerCase()

        data.value.filter((menuItem: any) => {

            menuItem.children.filter((menuItems: any) => {
                if (menuItems.title?.toLowerCase().includes(term) && menuItems.type === 'link' || menuItems.title?.toUpperCase().includes(term) && menuItems.type === 'link') {

                    items.push(menuItems);

                }
                menuItems.children?.filter((subItems: any) => {
                    if (subItems.title?.toLowerCase().includes(term) && subItems.type === 'link' || subItems.title?.toUpperCase().includes(term) && subItems.type === 'link') {
                        subItems.icon = menuItems.icon
                        items.push(subItems);

                    }
                    if (!subItems.children) return false;
                    subItems.children?.filter((suSubItems: any) => {
                        if (suSubItems.title?.toLowerCase().includes(term) || suSubItems.title?.toUpperCase().includes(term)) {
                            suSubItems.icon = menuItems.icon
                            items.push(suSubItems);
                        }
                    })

                })
                searchData.value = items;
            })
        })
    }
    function searchterm(terms: any) {
        const items: any = [];

        const searchval = terms.toLowerCase()

        data.value.filter((menuItem: any) => {
            menuItem.children.filter((menuItems: any) => {
                if (menuItems.title?.toLowerCase().includes(terms) && menuItems.type === 'link') {
                    items.push(menuItems);
                }
                menuItems.children?.filter((subItems: any) => {
                    if (subItems.title?.toLowerCase().includes(terms) && subItems.type === 'link') {
                        subItems.icon = menuItems.icon
                        items.push(subItems);

                    }
                    if (!subItems.children) return false;
                    subItems.children?.filter((suSubItems: any) => {
                        if (suSubItems.title?.toLowerCase().includes(terms)) {
                            suSubItems.icon = menuItems.icon
                            items.push(suSubItems);
                        }
                    })

                })
                searchDatas.value = items;
            })
        })
    }
    onMounted(() => {
        data.value.filter((menuItem: any) => {
            menuItem.children?.filter((menuItems: any) => {
                if (menuItems.path) {

                    if (menuItems.path == useRoute().path) {
                        perentName.value = menuItems.title
                    }
                } else {
                    if (menuItems.children) {
                        menuItems.children?.filter((subItem: any) => {
                            if (subItem.path) {
                                if (subItem.path == useRoute().path) {
                                    perentName.value = menuItems.title
                                    childName.value = subItem.name
                                }
                            }

                            if (subItem.children) {
                                subItem.children?.filter((childItem: any) => {
                                    if (childItem.path) {
                                        if (childItem.path == useRoute().path) {
                                            perentName.value = menuItems.title
                                            childName.value = childItem.name
                                        }
                                    }
                                })
                            }


                        })
                    }
                }
            })
        })
    })

    return {
        data,
        togglesidebar,
        activeoverlay,
        toggle_sidebar,
        customizer,
        searchTerm,
        togglePinned,
        searchterm,
        searchData,
        searchOpen,
        hideRightArrowRTL,
        hideLeftArrowRTL,
        hideRightArrow,
        hideLeftArrow,
        width,
        height,
        margin,
        menuWidth,
        searchDatas,
        bodyToggle,
        subMenuToggle,
        subChildMenu,
        childMenu,
        perentName,
        subName,
        childName,
        perentToggle,
        subToggle,
        childToggle,
        openActives,
        active,
        filteredMenuItems,
        searchQuery
    };
});
