import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo/logo_full.svg'
import _imports_1 from '@/assets/images/logo/logo_dark.png'


const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card login-dark" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-input position-relative" }
const _hoisted_13 = ["type"]
const _hoisted_14 = { class: "show-hide" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "form-input position-relative" }
const _hoisted_17 = ["type"]
const _hoisted_18 = { class: "show-hide" }
const _hoisted_19 = { class: "mt-4 mb-0" }

import {ref} from 'vue'
import {AuthService, emptyRegisterRequest, RegistrationRequest} from "@/api/auth";
import {APIError} from "@/api/errors";
import {toast} from "vue3-toastify";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'registerSimple',
  setup(__props) {

const type = ref<string>('password')

const router = useRouter()
const password = ref<string>('')
const formValues = ref<RegistrationRequest>({...emptyRegisterRequest})

function showPassword() {
  if (type.value === 'password') {
    type.value = 'text';
  } else {
    type.value = 'password';
  }
}

const createAccount = async () => {
  if (formValues.value.password !== password.value) {
    toast.error("passwords is not equals")
    return
  }
  const resp = await AuthService.register(formValues.value)
  if (resp instanceof APIError){
    toast.error(resp.message)
    return
  }
  await router.push('/')
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                class: "logo",
                to: "/"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    class: "img-fluid for-light",
                    src: _imports_0,
                    alt: "looginpage"
                  }, null, -1),
                  _createElementVNode("img", {
                    class: "img-fluid for-dark",
                    src: _imports_1,
                    alt: "looginpage"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "theme-form",
                onSubmit: _withModifiers(createAccount, ["prevent"])
              }, [
                _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Create your account", -1)),
                _cache[14] || (_cache[14] = _createElementVNode("p", null, "Enter your personal details to create account", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-form-label pt-0" }, "Your Name", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formValues.value.name) = $event)),
                        class: "form-control",
                        type: "text",
                        required: "",
                        placeholder: "Name"
                      }, null, 512), [
                        [_vModelText, formValues.value.name]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formValues.value.email) = $event)),
                    class: "form-control",
                    type: "email",
                    required: "",
                    placeholder: "your-email@gmail.com"
                  }, null, 512), [
                    [_vModelText, formValues.value.email]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-form-label" }, "Phone number", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formValues.value.phone) = $event)),
                    class: "form-control",
                    type: "text",
                    required: "",
                    placeholder: "+99512345678"
                  }, null, 512), [
                    [_vModelText, formValues.value.phone]
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formValues.value.password) = $event)),
                      class: "form-control",
                      type: type.value,
                      placeholder: "*********"
                    }, null, 8, _hoisted_13), [
                      [_vModelDynamic, formValues.value.password]
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", {
                        class: _normalizeClass(type.value == 'password' ? 'show' : ''),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-form-label" }, "Repeat password", -1)),
                  _createElementVNode("div", _hoisted_16, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((password).value = $event)),
                      class: "form-control",
                      type: type.value,
                      placeholder: "*********"
                    }, null, 8, _hoisted_17), [
                      [_vModelDynamic, password.value]
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", {
                        class: _normalizeClass(type.value == 'password' ? 'show' : ''),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"form-group mb-0\"><div class=\"checkbox p-0\"><input id=\"checkbox1\" type=\"checkbox\"><label class=\"text-muted\" for=\"checkbox1\">Agree with<a class=\"ms-2\" href=\"#\">Privacy Policy</a></label></div><button class=\"btn btn-primary btn-block w-100\" type=\"submit\">Create Account</button></div>", 1)),
                _createElementVNode("p", _hoisted_19, [
                  _cache[12] || (_cache[12] = _createTextVNode("Already have an account? ")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/login"
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("Sign in ")
                    ])),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})