interface MenuItem {
    title?: string;
    icon?: string;
    type?: string;
    path?: string;
    badgeType?: string;
    bagde?: string;
    active?: boolean;
    isPinned?: boolean;
    bookmark?: boolean;
    children?: MenuItem[];
}

interface MenuGroup {
    headTitle1: string;
    type: string;
    children: MenuItem[];
}
export const menu: MenuGroup[] = [
    {
        headTitle1: "- Settings",
        type: "headtitle",
        children: [
            {
                title: "User",
                icon: "stroke-user",
                type: "sub",
                active: false,
                isPinned: false,
                children: [
                    {
                        path: "/users/profile",
                        title: "Profile",
                        type: "link"
                    },
                    {
                        path: "/users/edit",
                        title: "Edit profile",
                        type: "link"
                    },
                ]
            },
        ]
    },
    // {
    //     headTitle1: "- General",
    //     type: "headtitle",
    //     children: [
    //         {
    //             title: "Dashboard",
    //             icon: "stroke-home",
    //             type: "sub",
    //             badgeType: "badge-light-primary",
    //             bagde: "3",
    //             active: false,
    //             isPinned: false,
    //             children: [
    //                 {
    //                     path: "/dashboard/default",
    //                     title: "Default",
    //                     type: "link"
    //                 },
    //
    //                 {
    //                     path: "/dashboard/ecommerce",
    //                     title: "Ecommerce",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/dashboard/project",
    //                     title: "project",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             title: "Widgets",
    //             icon: "stroke-widget",
    //             type: "sub",
    //             active: false,
    //             isPinned: false,
    //             children: [
    //                 {
    //                     path: "/widgets/general",
    //                     title: "General",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/widgets/Chart",
    //                     title: "Charts",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //     ]
    // },
    // {
    //     headTitle1: "- Applications",
    //     type: "headtitle",
    //     children: [
    //         {
    //             title: "project",
    //             icon: "stroke-project",
    //             type: "sub",
    //             badgeType: "badge-light-secondary",
    //             bagde: "New",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/project/project_list",
    //                     title: "Project List",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/project/project_create",
    //                     title: "Create new",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             path: "/app/file_manager",
    //             title: "File Manager",
    //             icon: "stroke-file",
    //             type: "link",
    //             isPinned: false,
    //             active: false
    //         },
    //         {
    //             path: "/app/kanban_board",
    //             title: "Kanban Board",
    //             icon: "stroke-board",
    //             type: "link",
    //             badgeType: "light-danger",
    //             isPinned: false,
    //             active: false
    //         },
    //         {
    //             title: "Ecommerce",
    //             icon: "stroke-ecommerce",
    //             type: "sub",
    //             active: false,
    //             isPinned: false,
    //             children: [
    //                 {
    //                     path: "/ecommerce/add_product",
    //                     title: "Add Product",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/product",
    //                     title: "Product",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/category",
    //                     title: "Category page",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/details/1",
    //                     title: "Product Page",
    //                     type: "link"
    //                 },
    //
    //                 {
    //                     path: "/ecommerce/product_list",
    //                     title: "Product List",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/payment_details",
    //                     title: "Payment Details",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/order_history",
    //                     title: "Order History",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/invoice",
    //                     title: "Invoices",
    //                     type: "sub",
    //                     children: [
    //                         {
    //                             path: "/ecommerce/invoice_1",
    //                             title: "Invoice-1",
    //                             type: "link",
    //                         },
    //                         {
    //                             path: "/ecommerce/invoice_2",
    //                             title: "Invoice-2",
    //                             type: "link",
    //                         },
    //                         {
    //                             path: "/ecommerce/invoice_3",
    //                             title: "Invoice-3",
    //                             type: "link",
    //                         },
    //                         {
    //                             path: "/ecommerce/invoice_4",
    //                             title: "Invoice-4",
    //                             type: "link",
    //                         },
    //                         {
    //                             path: "/ecommerce/invoice_5",
    //                             title: "Invoice-5",
    //                             type: "link",
    //                         },
    //                         {
    //                             path: "/ecommerce/invoice_6",
    //                             title: "Invoice-6",
    //                             type: "link",
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     path: "/ecommerce/cart",
    //                     title: "Cart",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/wishlist",
    //                     title: "Wishlist",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/checkout",
    //                     title: "Checkout",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/ecommerce/pricing",
    //                     title: "Pricing",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             path: "/app/letter_box",
    //             icon: "stroke-email",
    //             title: "Letter Box",
    //             isPinned: false,
    //             type: "link"
    //         },
    //         {
    //             title: "Chat",
    //             icon: "stroke-chat",
    //             type: "sub",
    //             active: false,
    //             isPinned: false,
    //             children: [
    //                 {
    //                     path: "/app/private_chat",
    //                     title: "Private Chat",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/app/group_chat",
    //                     title: "Group Chat",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             path: "/app/bookmark",
    //             title: "Bookmark",
    //             icon: "stroke-bookmark",
    //             type: "link",
    //             isPinned: false,
    //             bookmark: true
    //         },
    //         {
    //             path: "/app/contact",
    //             title: "Contacts",
    //             icon: "stroke-contact",
    //             type: "link",
    //             isPinned: false,
    //             bookmark: true
    //         },
    //         {
    //             path: "/app/task",
    //             title: "Tasks",
    //             icon: "stroke-task",
    //             isPinned: false,
    //             type: "link"
    //         },
    //         {
    //             path: "/app/calendar",
    //             title: "Calendar",
    //             icon: "stroke-calendar",
    //             type: "link",
    //             isPinned: false,
    //             bookmark: true
    //         },
    //         {
    //             path: "/pages/social_app",
    //             title: "Social App",
    //             icon: "stroke-social",
    //             isPinned: false,
    //             type: "link"
    //         },
    //         {
    //             path: "/app/todo",
    //             title: "Todo",
    //             icon: "stroke-to-do",
    //             isPinned: false,
    //             type: "link"
    //         },
    //
    //         {
    //             path: "/pages/search",
    //             title: "Search Results",
    //             icon: "stroke-search",
    //             isPinned: false,
    //             type: "link"
    //         },
    //
    //     ]
    // },
    // {
    //     headTitle1: "- Forms & Table",
    //     type: "headtitle",
    //     children: [
    //         {
    //             title: "Forms",
    //             icon: "stroke-form",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     title: "Form Controls",
    //                     type: "sub",
    //                     active: false,
    //                     children: [
    //                         {
    //                             path: "/form/validation",
    //                             title: "Form Validation",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/base_inputs",
    //                             title: "Base Inputs",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/checkbox_radio",
    //                             title: "Checkbox & Radio",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/input_groups",
    //                             title: "Input Groups",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/input_mask",
    //                             title: "Input Mask",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/mega_options",
    //                             title: "Mega Options",
    //                             type: "link"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: "Form Widgets",
    //                     type: "sub",
    //                     active: false,
    //                     children: [
    //                         {
    //                             path: "/form/datepicker",
    //                             title: "Datepicker",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/touchspin",
    //                             title: "Touchspin",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/select2",
    //                             title: "Select2",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/switch",
    //                             title: "Switch",
    //                             type: "link"
    //                         },
    //
    //                         {
    //                             path: "/form/typeahead",
    //                             title: "Typeahead",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/clipboard",
    //                             title: "Clipboard",
    //                             type: "link"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: "Form Layout",
    //                     type: "sub",
    //                     active: false,
    //                     children: [
    //                         {
    //                             path: "/form/form_wizard",
    //                             title: "Form Wizard 1",
    //                             icon: "file-text",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/form_wizard_two",
    //                             title: "Form Wizard 2",
    //                             icon: "file-text",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/form/two_actor",
    //                             title: "Two Factor",
    //                             icon: "file-text",
    //                             type: "link"
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             title: "Tables",
    //             icon: "stroke-table",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     title: "Bootstrap Tables",
    //                     path: "/table/basic",
    //                     type: "link",
    //                     active: false
    //                 },
    //                 {
    //                     path: "/table/table_components",
    //                     title: "Table Components",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/table/datatable_basic",
    //                     title: "Basic Init",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //     ]
    // },
    // {
    //     headTitle1: "- Components",
    //     type: "headtitle",
    //     children: [
    //         {
    //             title: "UI Kits",
    //             icon: "stroke-ui-kits",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/uikits/typography",
    //                     title: "Typography",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/avatars",
    //                     title: "Avatars",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/helper_classes",
    //                     title: "Helper Classes",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/grid",
    //                     title: "Grid",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/tag_pills",
    //                     title: "Tag & Pills",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/progress_bar",
    //                     title: "Progress",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/modal",
    //                     title: "Modal",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/alert",
    //                     title: "Alert",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/popover",
    //                     title: "Popover",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/tooltip",
    //                     title: "Tooltip",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/dropdown",
    //                     title: "Dropdown",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/uikits/accordion",
    //                     title: "Accordian",
    //                     type: "link"
    //                 },
    //                 {
    //                     title: "Tabs",
    //                     type: "link",
    //                     path: "/uikits/tabs",
    //                     active: false
    //                 },
    //                 {
    //                     path: "/uikits/lists",
    //                     title: "Lists",
    //                     type: "link"
    //                 }
    //
    //             ]
    //         },
    //         {
    //             title: "Bonus UI",
    //             icon: "stroke-bonus-kit",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/advance/scrollable",
    //                     title: "Scrollable",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/tree",
    //                     title: "Tree View",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/toasts",
    //                     title: "Toasts",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/rating",
    //                     title: "Rating",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/dropzone",
    //                     title: "Dropzone",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/tour",
    //                     title: "Tour",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/sweetalert",
    //                     title: "SweetAlert2",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/animated_modal",
    //                     title: "Animated Modal",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/owl_carousel",
    //                     title: "Owl Carousel",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/ribbons",
    //                     title: "Ribbons",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/pagination",
    //                     title: "Pagination",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/breadcrumb",
    //                     title: "Breadcrumb",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/range_slider",
    //                     title: "Range Slider",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/image_cropper",
    //                     title: "Image Cropper",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/basic_card",
    //                     title: "Basic Card",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/creative_card",
    //                     title: "Creative Card",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/draggable_card",
    //                     title: "Draggable Card",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/advance/timeline",
    //                     title: "Timeline",
    //                     icon: "folder-plus",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             title: "Animation",
    //             icon: "stroke-animation",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/animation/animate",
    //                     title: "Animate",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/animation/aos_animation",
    //                     title: "AOS Animation",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             title: "Icons",
    //             icon: "stroke-icons",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/icons/flag",
    //                     title: "Flag Icon",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/icons/fontawesome",
    //                     title: "Fontawesome Icon",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/icons/ico",
    //                     title: "Ico Icon",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/icons/themify",
    //                     title: "Themify Icon",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/icons/feather_icon",
    //                     title: "Feather Icon",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/icons/whether",
    //                     title: "Whether Icon",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //
    //         {
    //             path: "/app/buttons",
    //             title: "Buttons",
    //             icon: "stroke-button",
    //             type: "link",
    //             isPinned: false,
    //             active: false,
    //         },
    //         {
    //             title: "Charts",
    //             icon: "stroke-charts",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/chart/apexChart",
    //                     title: "ApexChart",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/chart/google",
    //                     title: "Google Chart",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/chart/chartist",
    //                     title: "Chartist",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //     ]
    // },
    // {
    //     headTitle1: "- Pages",
    //     type: "headtitle",
    //     children: [
    //         {
    //             path: "/pages/sample_page",
    //             title: "Sample page",
    //             icon: "stroke-sample-page",
    //             isPinned: false,
    //             type: "link"
    //         },
    //         {
    //             path: "/pages/internationalization",
    //             title: "Internationalization",
    //             icon: "stroke-internationalization",
    //             isPinned: false,
    //             type: "link"
    //         },
    //         {
    //             title: "Others",
    //             icon: "stroke-others",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     title: "Error Page",
    //                     type: "sub",
    //                     active: false,
    //                     children: [
    //                         {
    //                             path: "/error_page1",
    //                             title: "Error 400",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/error_page2",
    //                             title: "Error 401",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/error_page3",
    //                             title: "Error 403",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/error_page4",
    //                             title: "Error 404",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/error_page5",
    //                             title: "Error 500",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/error_page6",
    //                             title: "Error 503",
    //                             type: "link"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: "Authentication",
    //                     type: "sub",
    //                     active: false,
    //                     children: [
    //                         {
    //                             path: "/authentication/simple",
    //                             title: "Login Simple",
    //                             type: "link",
    //                             active: false
    //                         },
    //                         {
    //                             path: "/authentication/login/one",
    //                             title: "Visual Login",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/login/two",
    //                             title: "Visual Login 2",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/login/validate",
    //                             title: "validation Login",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/login/tooltip",
    //                             title: "Tooltip Login",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/login/sweetalert",
    //                             title: "Login with sweetalert",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/auth/register",
    //                             title: "Register",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/register/one",
    //                             title: "Register Image",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/register/two",
    //                             title: "Visual Register Image 2",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/unlock_user",
    //                             title: "Unlock User",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/forget_password",
    //                             title: "Forget Password",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/reset_password",
    //                             title: "Reset Password",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/authentication/maintenance",
    //                             title: "Maintenance",
    //                             type: "link"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     title: "Coming Soon",
    //                     icon: "bx:briefcase-alt",
    //                     type: "sub",
    //                     active: false,
    //                     children: [
    //                         {
    //                             path: "/comingsoon/comingsoon_simple",
    //                             title: "Coming Simple",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/comingsoon/comingsoon_image",
    //                             title: "Coming with Bg Image",
    //                             type: "link"
    //                         },
    //                         {
    //                             path: "/comingsoon/comingsoon_video",
    //                             title: "Coming with Bg video",
    //                             type: "link"
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //
    //
    //     ]
    // },
    // {
    //     headTitle1: "- Miscellaneous",
    //     type: "headtitle",
    //     children: [
    //         {
    //             title: "Gallery",
    //             icon: "stroke-gallery",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/gallery/grid_gallery",
    //                     title: "Grid Gallery",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/gallery/gallery_desc",
    //                     title: "Grid Gallery With Desc",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/gallery/gallery_masonary",
    //                     title: "Masonary Gallery",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/gallery/gallery_masonary_desc",
    //                     title: "Masonary Gallery Desc",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/gallery/hover_effect",
    //                     title: "Hover Effect",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //
    //         {
    //             title: "Blog",
    //             icon: "stroke-blog",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/blog/details",
    //                     title: "Blog Details",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/blog/single",
    //                     title: "Blog Single",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/blog/add_post",
    //                     title: "Add Post",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             path: "/pages/faq",
    //             title: "FAQ",
    //             icon: "stroke-faq",
    //             isPinned: false,
    //             type: "link"
    //         },
    //         {
    //             title: "Job Search",
    //             icon: "stroke-job-search",
    //             type: "sub",
    //             active: false,
    //             isPinned: false,
    //             children: [
    //                 {
    //                     path: "/job/card",
    //                     title: "Card View",
    //                     icon: "package",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/job/list",
    //                     title: "List View",
    //                     icon: "package",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/job/details/1",
    //                     title: "Job Details",
    //                     icon: "package",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/job/apply/1",
    //                     title: "Apply",
    //                     icon: "package",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             title: "Learning",
    //             icon: "stroke-learning",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/learning/list",
    //                     title: "Learning List",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/learning/details/1",
    //                     title: "Detailed Course",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //
    //         {
    //             title: "Maps",
    //             icon: "stroke-maps",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/maps/vue_google_maps",
    //                     title: "Google Maps",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/maps/vue_leaflet_maps",
    //                     title: "Vue Leaflet",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             title: "editor",
    //             icon: "stroke-editors",
    //             type: "sub",
    //             isPinned: false,
    //             active: false,
    //             children: [
    //                 {
    //                     path: "/editor/ck_editor",
    //                     title: "Ck Editor",
    //                     icon: "box",
    //                     type: "link"
    //                 },
    //                 {
    //                     path: "/editor/simple_editor",
    //                     title: "Simple Editor",
    //                     icon: "edit",
    //                     type: "link"
    //                 }
    //             ]
    //         },
    //         {
    //             path: "/knowledgebase/knowledgebase",
    //             title: "Knowledgebase",
    //             type: "link",
    //             isPinned: false,
    //             icon: "stroke-knowledgebase",
    //         },
    //         {
    //             path: "/pages/support",
    //             title: "Support Ticket",
    //             isPinned: false,
    //             icon: "stroke-support-tickets",
    //             type: "link"
    //         }
    //     ]
    // },
]