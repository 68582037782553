<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card login-dark">
          <div>
            <div>
              <router-link class="logo" to="/">
                <img class="img-fluid for-light"
                     src="@/assets/images/logo/logo_full.svg" alt="looginpage">
                <img class="img-fluid for-dark"
                     src="@/assets/images/logo/logo_dark.png" alt="looginpage">
              </router-link>
            </div>
            <div class="login-main">
              <form class="theme-form" @submit.prevent="">
                <h2>Sign in to account</h2>
                <p class="f-m-light mt-1">Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input v-model="email" class="form-control" type="email" required
                         placeholder="your-email@gmail.com">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input class="form-control" v-model="password" :type="type"
                           required placeholder="*********">
                    <div class="show-hide"><span :class="{show: !isShowPassword, hide: isShowPassword}"
                                                 @click="showPassword"> </span></div>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <router-link to="/authentication/forget_password">Forgot
                    password?
                  </router-link>
                  <div class="text-end mt-3">
                    <button class="btn btn-primary btn-block w-100" type="submit" @click="doLogin">Sign
                      in
                    </button>
                  </div>
                </div>
                <p class="mt-4 mb-0 text-center">Don't have account?
                  <router-link class="ms-2"
                               to="/auth/register">Create Account
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {ref} from "vue"
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {useRouter} from 'vue-router'
import {AuthService} from "@/api/auth";
import {APIError} from "@/api/errors";

const type = ref<string>('password')
const email = ref<string>("")
const password = ref<string>("")
const router = useRouter()
const isShowPassword = ref(false)

function showPassword() {
  if (isShowPassword.value) {
    type.value = 'password'
  } else {
    type.value = 'text'
  }
  isShowPassword.value = !isShowPassword.value;
}

async function doLogin() {
  const res = await AuthService.auth({username: email.value, password: password.value})
  if (res instanceof APIError) {
    toast.error(res.message, {position: 'top-right', autoClose: 5000});
    return
  }
  router.replace('/');
  localStorage.setItem('user', email.value)
  localStorage.setItem('token', res.token)
  localStorage.setItem("SidebarType", 'compact-wrapper')
}
</script>

<style scoped>
.img-fluid {
  height: auto !important;
}
</style>