import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-body-wrapper" }
const _hoisted_2 = ["data-layout"]
const _hoisted_3 = { class: "page-body" }

import { defineAsyncComponent, ref, onMounted, watch } from 'vue'
import { useMenuStore } from "@/store/menu";
import { uselayoutStore } from "@/store/layout";
import { storeToRefs } from "pinia";

export default /*@__PURE__*/_defineComponent({
  __name: 'BodyView',
  emits: ['click'],
  setup(__props, { emit: __emit }) {

const TapTop = defineAsyncComponent(() => import("@/layout/TapTop.vue"))
const FooterView = defineAsyncComponent(() => import("@/components/common/block/footer/FooterView.vue"))
const Sidebar = defineAsyncComponent(() => import("@/components/common/block/sidebar/index.vue"))
const Header = defineAsyncComponent(() => import("@/components/common/block/header/index.vue"))
// const Customizer = defineAsyncComponent(() => import("@/components/common/block/customizer/indexCustomizer.vue"))
let emit = __emit;
let sidebar_toggle_var = ref<boolean>(false);
let display = ref<boolean>(false)
let layoutobj = ref<any>({});
let store = useMenuStore();
let storeLayout = uselayoutStore();
const { layouts: layouts } = storeToRefs(storeLayout);
watch(
    () => layouts,
    () => {
        layoutobj.value = storeLayout.layouts.settings.sidebar_setting;
    },
    { deep: true },
);
watch(
    () => "router",
    () => {

        if ((window.innerWidth < 991 && storeLayout.layouts.settings.layout === 'Horizontal')) {
            const newlayout = JSON.parse(JSON.stringify(layoutobj).replace('horizontal-wrapper', 'compact-sidebar compact-small material-icon'));
            layoutobj.value = JSON.parse(JSON.stringify(newlayout))[storeLayout.layouts.settings.layout];
        } else {
            layoutobj.value = JSON.parse(JSON.stringify(layoutobj))[storeLayout.layouts.settings.layout];
        }
    }
)
function sidebar_toggle(value: boolean) {
    sidebar_toggle_var.value = !value;
}
function handleScroll() {
    if (window.innerWidth <= 1199) {
        display.value = true;
        store.togglesidebar = false

    } else {
        store.togglesidebar = true
        display.value = false;
    }
}
onMounted(() => {

    window.addEventListener('resize', handleScroll);
    layoutobj.value = storeLayout.layouts.settings.sidebar_setting;

});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(TapTop)),
    _createElementVNode("div", {
      class: _normalizeClass(["page-wrapper", _unref(display) ? 'compact-wrapper ' : _unref(layoutobj)]),
      id: "pageWrapper"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["page-header row", { close_icon: !_unref(store).togglesidebar }])
      }, [
        _createVNode(_unref(Header), { onClick: sidebar_toggle })
      ], 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["sidebar-wrapper", [{ close_icon: !_unref(store).togglesidebar, 'sidebar-default': _unref(store).perentName }]]),
          "data-layout": _unref(storeLayout).svg == 'stroke-svg' ? 'stroke-svg' : 'fill-svg'
        }, [
          _createVNode(_unref(Sidebar), { onClick: sidebar_toggle })
        ], 10, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_unref(FooterView))
      ])
    ], 2)
  ], 64))
}
}

})