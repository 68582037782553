import {defineStore} from "pinia";
import {User} from "@/api/user";

export interface UserStoreModel {
    user: User | null
}

export const useUserStore = defineStore("user", {
    state: () => ({user: null} as UserStoreModel),
    actions: {
        setUser(user: User| null) {
            this.user = user
        }
    }
})