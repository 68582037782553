import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-9 col-xl-8 box-col-12" }
const _hoisted_5 = { class: "email-right-aside" }

import { defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexLetterBox',
  setup(__props) {

const LetterBoxSidebar = defineAsyncComponent(() => import("@/components/theme/letterbox/LetterBoxSidebar.vue"))
const LetterBoxHeader = defineAsyncComponent(() => import("@/components/theme/letterbox/LetterBoxHeader.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(LetterBoxSidebar)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(LetterBoxHeader))
          ])
        ])
      ])
    ])
  ]))
}
}

})