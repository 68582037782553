import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { ref, defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexCreateProject',
  setup(__props) {

const Card3 = defineAsyncComponent(() => import("@/components/common/card/CardData3.vue"))
const NewProject = defineAsyncComponent(() => import("@/components/theme/project/createproject/NewProject.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Card3), { colClass: "col-sm-12" }, {
        default: _withCtx(() => [
          _createVNode(_unref(NewProject))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})