import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo/logo.png'
import _imports_1 from '@/assets/images/logo/logo_dark.png'


const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "login-card login-dark" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "theme-form" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-input position-relative" }
const _hoisted_9 = ["type"]
const _hoisted_10 = { class: "show-hide" }
const _hoisted_11 = { class: "mt-4 mb-0 text-center" }

import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'forgetPassword',
  setup(__props) {

const type = ref<string>('password')
function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                class: "logo",
                to: "/"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("img", {
                    class: "img-fluid for-light",
                    src: _imports_0,
                    alt: "looginpage"
                  }, null, -1),
                  _createElementVNode("img", {
                    class: "img-fluid for-dark",
                    src: _imports_1,
                    alt: "looginpage"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", _hoisted_6, [
                _cache[4] || (_cache[4] = _createStaticVNode("<h2>Reset Your Password</h2><div class=\"form-group\"><label class=\"col-form-label\">Enter Your Mobile Number</label><div class=\"row\"><div class=\"col-4 col-sm-3\"><input class=\"form-control mb-1\" type=\"text\" value=\"+ 91\"></div><div class=\"col-8 col-sm-9\"><input class=\"form-control mb-1\" type=\"tel\" value=\"000-000-0000\"></div><div class=\"col-12\"><div class=\"text-end\"><button class=\"btn btn-primary btn-block m-t-10\" type=\"submit\">Send</button></div></div></div></div><div class=\"mt-4 mb-4\"><span class=\"reset-password-link\">If don&#39;t receive OTP?  <a class=\"btn-link text-danger\" href=\"#\">Resend</a></span></div><div class=\"form-group\"><label class=\"col-form-label pt-0\">Enter OTP</label><div class=\"row\"><div class=\"col\"><input class=\"form-control text-center opt-text\" type=\"text\" value=\"00\" maxlength=\"2\"></div><div class=\"col\"><input class=\"form-control text-center opt-text\" type=\"text\" value=\"00\" maxlength=\"2\"></div><div class=\"col\"><input class=\"form-control text-center opt-text\" type=\"text\" value=\"00\" maxlength=\"2\"></div></div></div><h4 class=\"mt-4\">Create Your Password</h4>", 5)),
                _createElementVNode("div", _hoisted_7, [
                  _cache[1] || (_cache[1] = _createElementVNode("label", { class: "col-form-label" }, "New Password", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("input", {
                      class: "form-control",
                      type: type.value,
                      name: "login[password]",
                      required: "",
                      placeholder: "*********"
                    }, null, 8, _hoisted_9),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", {
                        class: _normalizeClass(type.value == 'password' ? 'show' : ''),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"form-group\"><label class=\"col-form-label\">Retype Password</label><input class=\"form-control\" type=\"password\" name=\"login[password]\" required placeholder=\"*********\"></div><div class=\"form-group mb-0\"><div class=\"checkbox p-0\"><input id=\"checkbox1\" type=\"checkbox\"><label class=\"text-muted\" for=\"checkbox1\">Remember password</label></div><button class=\"btn btn-primary btn-block w-100\" type=\"submit\">Done </button></div>", 2)),
                _createElementVNode("p", _hoisted_11, [
                  _cache[3] || (_cache[3] = _createTextVNode("Already have an password?")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/login"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Sign in")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})