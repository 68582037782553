interface Product {
    sku: number;
    name: string;
    images: string[];
    shortDescription: string;
    description: string;
    details: string;
    salePrice: string;
    price: string;
    sale: boolean;
    off?: boolean;
    category: string[];
    colors: string[];
    size: string[];
    brand: string;
    tags: string[];
    stock: number;
    quantity: number;
    star: number;
    icon: boolean;
    ribbon: boolean;
    hot: boolean;
}
export const data: Product[] = [
    {
        sku: 1,
        name: "Women's Top",
        images: [
            "ecommerce/01.jpg",
            "ecommerce/02.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Regular Flare Fit Women's white Top",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "35.00",
        price: "50.00 ",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["white", "black", "green"],
        size: ["M", "L", "XL"],
        brand: "Levi's",
        tags: ["Woman Jeans", "white", "black", "green", "Levi's"],
        stock: 10,
        quantity: 1,
        star: 3,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 2,
        name: "Men's Jacket",
        images: [
            "ecommerce/02.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Cotton Blend Men's Blue Jacket",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "50.00",
        price: "45.00 ",
        sale: true,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "green", "red"],
        size: ["M", "L", "XL"],
        brand: "Lee",
        tags: [
            "Man Jeans",
            "Mens T-Shirt",
            "yellow",
            "gray",
            "green",
            "Lee",
            "red"
        ],
        quantity: 1,
        stock: 10,
        star: 4,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 3,
        name: "Women's Coat",
        images: [
            "ecommerce/03.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Cotton Blend Women's Coat",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. ",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "480.0",
        price: "426.00",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Denizen",
        tags: ["Woman Jeans", "yellow", "gray", "green", "Denizen"],
        quantity: 1,
        stock: 10,
        star: 2,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 4,
        name: "Men's Shirt",
        images: [
            "ecommerce/04.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Cotton Regular Fit Men's Shirt",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "35.00",
        price: "26.00",
        sale: false,
        off: true,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "white"],
        size: ["M", "L", "XL"],
        brand: "Spykar",
        tags: ["Man Jeans", "Mens T-Shirt", "yellow", "gray", "white", "Spykar"],
        quantity: 1,
        stock: 10,
        star: 5,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 5,
        name: "Track Suit",
        images: [
            "ecommerce/05.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/02.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Cotton Regular Fit Men's Shirt",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "25.00",
        price: "20.00 ",
        sale: false,
        off: false,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "white"],
        size: ["M", "L", "XL"],
        brand: "Spykar",
        tags: ["Man Jeans", "Mens T-Shirt", "yellow", "gray", "white", "Spykar"],
        quantity: 1,
        stock: 10,
        star: 4,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 6,
        name: "Women's Jacket",
        images: [
            "ecommerce/06.jpg",
            "ecommerce/02.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Solid Denim Jacket",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "30.00",
        price: "25.00",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["white", "black", "green"],
        size: ["M", "L", "XL"],
        brand: "Lee",
        tags: ["Woman Jeans", "white", "black", "green", "Lee"],
        quantity: 1,
        stock: 10,
        star: 3,
        icon: true,
        ribbon: false,
        hot: false
    },
    {
        sku: 7,
        name: "Denim Jacket",
        images: [
            "ecommerce/07.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/02.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Denim Regular Men's Jacket",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "35.00",
        price: "30.00",
        sale: false,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Lee",
        tags: [
            "Woman Top",
            "Man Jeans",
            "Mens T-Shirt",
            "yellow",
            "gray",
            "green",
            "Lee"
        ],
        quantity: 1,
        stock: 10,
        star: 4,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 8,
        name: "Men's Formal shirt",
        images: [
            "ecommerce/08.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/02.jpg"
        ],
        shortDescription: "Regular Men's Denim Shirt.",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. ",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "40.00 ",
        price: "35.00 ",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Hudson",
        tags: ["Man Shirt", "yellow", "gray", "green", "Hudson"],
        quantity: 1,
        stock: 10,
        star: 3,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 9,
        name: "Woman's Gown",
        images: [
            "ecommerce/01.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/02.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Woman's Long Gown",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. ",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "50.00",
        price: "45.00 ",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Hudson",
        tags: ["Woman Jeans", "yellow", "gray", "green", "Hudson"],
        quantity: 1,
        stock: 10,
        star: 4,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 10,
        name: "Men's Black Suit",
        images: [
            "ecommerce/02.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Men's Full Black Suit",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "55.00",
        price: "50.00 ",
        sale: false,
        off: false,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "white"],
        size: ["M", "L", "XL"],
        brand: "Spykar",
        tags: ["Man Jeans", "Mens T-Shirt", "yellow", "gray", "white", "Spykar"],
        quantity: 1,
        stock: 10,
        star: 5,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 11,
        name: "Woman's Dress",
        images: [
            "ecommerce/03.jpg",
            "ecommerce/02.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Tops for Stylish Woman's Dress",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "65.00",
        price: "60.00",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["white", "black", "green"],
        size: ["M", "L", "XL"],
        brand: "Diesel",
        tags: ["Woman Jeans", "white", "black", "green", "Diesel"],
        quantity: 1,
        stock: 10,
        star: 5,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 12,
        name: "Slim Fit Plastic Coat",
        images: [
            "ecommerce/04.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/02.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Slim Fit Plastic Thunder Coat",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "35.00",
        price: "30.00 ",
        sale: false,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Diesel",
        tags: ["Man Jeans", "Mens T-Shirt", "yellow", "gray", "green", "Diesel"],
        quantity: 1,
        stock: 10,
        star: 3,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 13,
        name: "Women's Hoodie",
        images: [
            "ecommerce/05.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/01.jpg",
            "ecommerce/02.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Women's Hoodie T-Shirt",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "360.00 ",
        price: "30.00 ",
        sale: false,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Diesel",
        tags: ["Woman Top", "yellow", "gray", "green", "Diesel"],
        quantity: 1,
        stock: 10,
        star: 4,
        icon: false,
        ribbon: true,
        hot: false
    },
    {
        sku: 14,
        name: "Men's T-shirt",
        images: [
            "ecommerce/06.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/02.jpg",
            "ecommerce/07.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Men's Denim T-shirt",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. ",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "70.00",
        price: "60.00",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["yellow", "gray", "green"],
        size: ["M", "L", "XL"],
        brand: "Denizen",
        tags: ["Man Shirt", "yellow", "gray", "green", "Denizen"],
        quantity: 1,
        stock: 10,
        star: 4,
        icon: false,
        ribbon: false,
        hot: false
    },
    {
        sku: 15,
        name: "Woman Party Wear",
        images: [
            "ecommerce/07.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/01.jpg",
            "ecommerce/06.jpg",
            "ecommerce/02.jpg",
            "ecommerce/08.jpg"
        ],
        shortDescription: "Stylish Party Wear for women",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "350.00",
        price: "30.00",
        sale: false,
        off: false,
        category: ["Man Shirt", "Man Jeans", "Mens T-Shirt"],
        colors: ["yellow", "gray", "white"],
        size: ["M", "L", "XL"],
        brand: "biba",
        tags: ["Woman Top", "yellow", "gray", "white", "biba"],
        quantity: 1,
        stock: 10,
        star: 5,
        icon: false,
        ribbon: false,
        hot: true
    },
    {
        sku: 16,
        name: "Dream Beauty Fashion",
        images: [
            "ecommerce/08.jpg",
            "ecommerce/02.jpg",
            "ecommerce/03.jpg",
            "ecommerce/04.jpg",
            "ecommerce/05.jpg",
            "ecommerce/06.jpg",
            "ecommerce/07.jpg",
            "ecommerce/01.jpg"
        ],
        shortDescription: "Tops for Women Stylish",
        description: "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.",
        details: "This is where some detailes on monkies would go. This monkey done seent some shit.",
        salePrice: "360.00",
        price: "30.00 ",
        sale: false,
        category: ["Woman Top", "Woman Jeans"],
        colors: ["white", "black", "green"],
        size: ["M", "L", "XL"],
        brand: "Levi's",
        tags: ["Woman Jeans", "white", "black", "green", "Levi's"],
        quantity: 1,
        stock: 10,
        star: 5,
        icon: false,
        ribbon: false,
        hot: false
    }
]
