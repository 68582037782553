import {APIError, apiErrorFromResponse} from "@/api/errors";
import {baseApiUrl} from "@/api/core";

interface CreateFeedbackRequest {
    message: string;
}

class FeedbackAPIService {
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async addFeedback(req: CreateFeedbackRequest): Promise<null | APIError> {
        const response = await fetch(this.baseUrl, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(req)
        });
        if (!response.ok) {
            return apiErrorFromResponse(response);
        }
        return null;
    }
}

export const FeedbackService = new FeedbackAPIService(`${baseApiUrl}/v1/feedback` );