import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo/logo.png'
import _imports_1 from '@/assets/images/logo/logo_dark.png'


const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "authentication-main mt-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "login-card login-dark" }
const _hoisted_6 = { class: "login-main" }
const _hoisted_7 = { class: "theme-form" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-input position-relative" }
const _hoisted_10 = ["type"]
const _hoisted_11 = { class: "show-hide" }
const _hoisted_12 = { class: "mt-4 mb-0" }

import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'unlockUser',
  setup(__props) {

const type = ref<string>('password')
function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  class: "logo",
                  to: "/"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      class: "img-fluid for-light",
                      src: _imports_0,
                      alt: "looginpage"
                    }, null, -1),
                    _createElementVNode("img", {
                      class: "img-fluid for-dark",
                      src: _imports_1,
                      alt: "looginpage"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("form", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Unlock ", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[1] || (_cache[1] = _createElementVNode("label", { class: "col-form-label" }, "Enter your Password", -1)),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("input", {
                        class: "form-control",
                        type: type.value,
                        name: "login[password]",
                        required: "",
                        placeholder: "*********"
                      }, null, 8, _hoisted_10),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", {
                          class: _normalizeClass(type.value == 'password' ? 'show' : ''),
                          onClick: showPassword
                        }, null, 2)
                      ])
                    ])
                  ]),
                  _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"form-group mb-0\"><div class=\"checkbox p-0\"><input id=\"checkbox1\" type=\"checkbox\"><label class=\"text-muted\" for=\"checkbox1\">Remember password</label></div><button class=\"btn btn-primary btn-block w-100\" type=\"submit\">Unlock</button></div>", 1)),
                  _createElementVNode("p", _hoisted_12, [
                    _cache[3] || (_cache[3] = _createTextVNode("Already Have an account?")),
                    _createVNode(_component_router_link, {
                      class: "ms-2",
                      to: "/auth/login"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Sign in")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})