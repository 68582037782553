<template>
    <div class="container-fluid dashboard2">
        <div class="row">
            <indexLeftEcommerce />
            <indexRightEcommerce />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
const indexLeftEcommerce = defineAsyncComponent(() => import("@/components/theme/dashboards/ecommerce/indexLeftEcommerce.vue"))
const indexRightEcommerce = defineAsyncComponent(() => import("@/components/theme/dashboards/ecommerce/indexRightEcommerce.vue"))

</script>