import {APIError, apiErrorFromResponse} from "@/api/errors";
import {baseApiUrl} from "@/api/core";
import {User} from "@/api/user";

export interface AuthRequest {
    username: string;
    password: string;
}

export interface AuthResponse {
    token: string;
    expires: string;
}


export interface UpdateUserRequest {
    name: string;
    email: string;
    phone: string;
    username: string;
}

export interface UpdateUserPasswordRequest {
    password: string;
    repeat_password: string;
}

export interface RegistrationRequest {
    name: string
    password: string
    email: string
    phone: string
}

export const emptyRegisterRequest: RegistrationRequest = {
    name: "",
    email: "",
    phone: "",
    password: "",
}

export interface RegistrationResponse {
    token: string;
    expires: string;
    user: User;
}

export class AuthAPIService {
    baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    async auth(req: AuthRequest): Promise<AuthResponse | APIError> {
        const response = await fetch(`${this.baseURL}/l`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(req),
            headers: {"Content-Type": "application/json"}
        });
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return await response.json();
    }

    async logout(): Promise<null | APIError> {
        const response = await fetch(`${this.baseURL}/o`, {
            method: "POST",
            credentials: "include",
        });
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return null;
    }

    async register(req: RegistrationRequest): Promise<RegistrationResponse | APIError> {
        const response = await fetch(`${this.baseURL}/reg`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(req),
            headers: {"Content-Type": "application/json"}
        });
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return await response.json();
    }
}

export const AuthService = new AuthAPIService(`${baseApiUrl}/auth`)