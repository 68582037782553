<template>
    <div class="container-fluid">
        <div class="row">
            <UpgradeCard />
            <ActiveProjects col="col-xl-3 col-sm-6" />
            <ProjectsEarnings />
            <TotalClients />
            <ProjectsOverview />
            <CalendarView />
            <ProjectsStatus />
            <ChatApplication />
            <RecentProject />
            <AvgEarnings />
            <DailyTask />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
const UpgradeCard = defineAsyncComponent(() => import("@/components/theme/dashboards/project/UpgradeCard.vue"))
const ActiveProjects = defineAsyncComponent(() => import("@/components/common/ActiveProjects.vue"))
const ProjectsEarnings = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ProjectsEarnings.vue"))
const TotalClients = defineAsyncComponent(() => import("@/components/theme/dashboards/project/TotalClients.vue"))
const ProjectsOverview = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ProjectsOverview.vue"))
const CalendarView = defineAsyncComponent(() => import("@/components/theme/dashboards/project/CalendarView.vue"))
const ProjectsStatus = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ProjectsStatus.vue"))
const ChatApplication = defineAsyncComponent(() => import("@/components/theme/dashboards/project/ChatApplication.vue"))
const RecentProject = defineAsyncComponent(() => import("@/components/theme/dashboards/project/RecentProject.vue"))
const AvgEarnings = defineAsyncComponent(() => import("@/components/theme/dashboards/project/AvgEarnings.vue"))
const DailyTask = defineAsyncComponent(() => import("@/components/theme/dashboards/project/DailyTask.vue"))
</script>