import {APIError, apiErrorFromResponse} from "@/api/errors";
import {baseApiUrl} from "@/api/core";
import {UpdateUserPasswordRequest, UpdateUserRequest} from "@/api/auth";

export enum Role {
    Simple = 1,
    Admin = 255,
    SuperUser = 512,
}


export interface User {
    id: string,
    created_at: string,
    updated_at: string,
    last_login_at: string,
    username: string,
    name: string,
    email: string,
    phone: string,
    is_active: boolean,
    auth_kind: string,
    roles: Role[],
    avatar: string,
    balances: number | null,
}

export const emptyUser: User = {
    id: "",
    created_at: "",
    updated_at: "",
    last_login_at: "",
    username: "",
    name: "",
    email: "",
    phone: "",
    is_active: false,
    auth_kind: "",
    roles: [],
    avatar: "",
    balances: null,
}


class UserAPIService {
    readonly baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    async getMe(): Promise<User | APIError> {
        const response = await fetch(`${this.baseURL}/me`, {method: "GET", credentials: "include"})
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return await response.json();
    }

    async updateMeProfile(req: UpdateUserRequest): Promise<User | APIError> {
        const response = await fetch(`${this.baseURL}/me`, {
            method: "POST",
            credentials: "include",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(req)
        });
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return await response.json();
    }

    async updateAvatar(formData: FormData): Promise<User | APIError> {
        const response = await fetch(`${this.baseURL}/me`, {
            method: "POST",
            credentials: "include",
            body: formData
        })
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return await response.json();
    }

    async updatePassword(req: UpdateUserPasswordRequest): Promise<void | APIError> {
        const response = await fetch(`${this.baseURL}/me/password`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(req),
            headers: {"Content-Type": "application/json"}
        });
        if (!response.ok) {
            return await apiErrorFromResponse(response);
        }
        return
    }
}

export const UserService = new UserAPIService(`${baseApiUrl}/v1/users`)