import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexProduct',
  setup(__props) {

const ProductHeader = defineAsyncComponent(() => import("@/components/theme/ecommerce/product/ProductHeader.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ProductHeader)))
}
}

})