import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid jkanban-container" }
const _hoisted_2 = { class: "row" }

import { ref, defineAsyncComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'indexKanbanBoard',
  setup(__props) {

const DefaultDemo = defineAsyncComponent(() => import("@/components/theme/kanbanboard/DefaultDemo.vue"))
const CustomDome = defineAsyncComponent(() => import("@/components/theme/kanbanboard/CustomDome.vue"))
const ApiDome = defineAsyncComponent(() => import("@/components/theme/kanbanboard/ApiDome.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(DefaultDemo)),
      _createVNode(_unref(CustomDome)),
      _createVNode(_unref(ApiDome))
    ])
  ]))
}
}

})